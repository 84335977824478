import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useImmer } from "use-immer";
import { useGetApi } from "common/hooks/useGetApi";
import api from "helpers/api.helpers";

import SearchableRecipes from "./SearchableRecipes";
import TextInput from "foundation/components/forms/TextInput";
import Plus from "foundation/svgComponents/Plus";
import X from "foundation/svgComponents/X";

import { Cookbook, Recipe } from "cooking/models/types";

type CookbookFormData = {
    name: string;
    description: string;
    isPublic: boolean;
    recipes: Recipe[];
};

const CreateCookbook: React.FC = () => {
    const navigate = useNavigate();
    const [cookbook, updateCookbook] = useImmer<CookbookFormData>({
        name: "",
        description: "",
        isPublic: false,
        recipes: [],
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { appendData: addToMyCookbooksCache } = useGetApi<Cookbook[]>(
        "cookbooks/my_cookbooks",
        { skip: true }
    );

    const handleRecipeClick = (recipe: Recipe) => {
        updateCookbook((draft) => {
            draft.recipes.push(recipe);
        });
    };

    const removeRecipe = (recipeId: number) => {
        updateCookbook((draft) => {
            draft.recipes = draft.recipes.filter(
                (recipe) => recipe.id !== recipeId
            );
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        api.post("/cookbooks/", {
            name: cookbook.name,
            description: cookbook.description,
            isPublic: cookbook.isPublic,
            recipeIds: cookbook.recipes.map((r) => r.id),
        })
            .then((resp) => {
                if (!resp) throw Error("Failed to create cookbook, idk why");
                addToMyCookbooksCache(resp.data, true, true);
                navigate("/cooking");
            })
            .catch((e) => {
                console.error("e:", e);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-6">
                <h1 className="">Create New Cookbook</h1>

                <TextInput
                    name="Name"
                    value={cookbook.name}
                    onChange={(value) =>
                        updateCookbook((draft) => {
                            draft.name = value;
                        })
                    }
                    required
                    placeholder="My Favorite Recipes"
                />

                <TextInput
                    name="Description"
                    value={cookbook.description}
                    onChange={(value) =>
                        updateCookbook((draft) => {
                            draft.description = value;
                        })
                    }
                    placeholder="A collection of my favorite recipes..."
                />

                <div className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        id="public"
                        checked={cookbook.isPublic}
                        onChange={(e) =>
                            updateCookbook((draft) => {
                                draft.isPublic = e.target.checked;
                            })
                        }
                    />
                    <label htmlFor="public" className="text-md">
                        Make cookbook public
                    </label>
                </div>

                <div className="space-y-3">
                    <label className="block text-[22px]">
                        Selected Recipes
                    </label>
                    <div className="flex flex-wrap gap-2">
                        {cookbook.recipes.map((recipe) => (
                            <div
                                key={recipe.id}
                                className="flex items-center gap-2 px-3 py-1"
                            >
                                <span className="text-[16px]">
                                    {recipe.name}
                                </span>
                                <button
                                    type="button"
                                    onClick={() => removeRecipe(recipe.id)}
                                    className="text-gray-500 hover:text-gray-700"
                                >
                                    <X size="12px" />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>

                <SearchableRecipes
                    titleOverride="Add Recipes"
                    onRecipeClick={handleRecipeClick}
                    recipesToExclude={cookbook.recipes}
                    rowIcon={<Plus />}
                />

                <button
                    type="submit"
                    disabled={
                        isSubmitting ||
                        !cookbook.name ||
                        cookbook.recipes.length === 0
                    }
                    className="w-full btn btn-primary disabled:opacity-70 transition-colors"
                >
                    {isSubmitting ? "Creating..." : "Create Cookbook"}
                </button>
            </div>
        </form>
    );
};

export default CreateCookbook;
