import { Choices } from "foundation/components/forms/types";

export type Tag = {
    id?: number;
    content: string;
    type?: "recipe" | "ingredient" | "kitchen_ingredient";
};

export type Recipe = {
    DESIRE_TO_MAKE_CHOICES: Choices;
    DIFFICULTY_CHOICES: Choices;
    RATING_CHOICES: Choices;
    STATUS_CHOICES: Choices;
    createdDate: string;
    creator: any;
    desireToMake?: "5" | "4" | "3" | "2" | "1" | null;
    difficulty?: "1" | "2" | "3" | "4" | "5" | null;
    id: number;
    name: string;
    rating?: "5" | "4" | "3" | "2" | "1" | null;
    servings?: number | null;
    source?: string | null;
    status?: "1" | "2" | "3" | "4";
    time?: string | null;

    tags: Tag[];
    ingredients: RecipeIngredient[];
    steps: ComplexStep[];
};

export type RecipeIngredient = {
    id: number;
    recipe?: number;
    ingredient?: number;
    name: string;
    quantity: string;
    originalText: string;
    unit: string;
    AVAILABLE_UNITS?: Choices;
    isInStock?: boolean;
};

export const STEP_TYPE = {
    single: "single",
    group: "group",
} as const;
export type StepType = (typeof STEP_TYPE)[keyof typeof STEP_TYPE];

export type Step =
    | {
        id: number;
        recipe?: number;
        number: number;
        text: string;
        type: StepType;

        ingredients?: Ingredient[];
        dependsOn?: number[];
        dependencies?: number[];
    } & StepGroup; // TODO should be a way to say "and maybe these fields"

export type StepGroup = {
    groupTitle?: string;
    groupSteps?: Step[];
};

export type ComplexStep = {
    id: number;
    recipe?: number;
    ingredients: RecipeIngredient[];
    order: number;
    dependsOn?: number[]; // ComplexStep.id[]
    dependencies?: number[]; // ComplexStep.id[]

    type: string; // "single" | "group"; ts is annoying about this
    text?: string;
    groupTitle?: string;
    groupSteps?: ComplexStep[];
};

export type Ingredient = {
    id: number;
    name: string;
    description: string;
    creator?: number;
    tags?: Tag[];
};

export type KitchenIngredient = {
    id: number;
    ingredient: Ingredient;
    inStock: boolean;
    quantity?: number;
    unit?: string;
    measurement?: string;
    tags: Tag[];
};

export type SimpleMealPlan = {
    id: number;
    recipes: Recipe[];
    creator: number;
    dateCreated: string;
};

export type Cookbook = {
    id: number;
    owner: string; // username of the owner
    name: string;
    description: string;
    recipes: Recipe[];
    createdAt: string; // ISO date string
    updatedAt: string; // ISO date string
    isPublic: boolean;
};
