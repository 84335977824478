import React from "react";
import useModal from "foundation/modals/useModal";

type ConfirmationProps = {
    text: string;
    onConfirm: () => void;
    onCancel: () => void;
};

const Confirmation: React.FC<ConfirmationProps> = ({
    text,
    onConfirm,
    onCancel,
}) => {
    const { closeModal } = useModal();

    const handleYes = () => {
        closeModal();
        onConfirm();
    };
    const handleNo = () => {
        closeModal();
        onCancel();
    };

    return (
        <section className="flex flex-col items-center mt-0 space-y-6">
            <h1 className="">{text}</h1>
            <div className="flex gap-[10px]">
                <button className="btn btn-primary" onClick={handleYes}>
                    Yes
                </button>
                <button className="btn btn-warning" onClick={handleNo}>
                    No
                </button>
            </div>
        </section>
    );
};

export default Confirmation;
