import React, { useEffect, useState } from "react";
import { useGetApi } from "common/hooks/useGetApi";
import { Recipe, Tag } from "cooking/models/types";

import RecipeRows from "cooking/components/RecipeRows";
import TextInput from "foundation/components/forms/TextInput";
import TagFilter from "foundation/components/TagFilter";

type SearchableRecipesProps = {
    recipesToExclude?: Recipe[];
    onRecipeClick?: (recipe: Recipe) => void;
    rowIcon?: React.ReactNode; // Override for the right icon. Defaults to RightArrow
    titleOverride?: string;
};

const SearchableRecipes: React.FC<SearchableRecipesProps> = ({
    recipesToExclude,
    onRecipeClick,
    rowIcon,
    titleOverride,
}) => {
    const [filteredRecipes, setFilteredRecipes] = useState<Recipe[]>([]);
    const [filterText, setFilterText] = useState("");
    const [tagFilters, setTagFilters] = useState<Tag[]>([]);

    const { data: recipes, isLoading } = useGetApi<Recipe[]>("recipe", {
        postprocess: (recipes) => {
            // Sort highest rated first
            return recipes.sort((a, b) => (a.rating < b.rating ? 1 : -1));
        },
    });

    const { data: tags } = useGetApi<Tag[]>("tag?type=recipe");

    useEffect(() => {
        setFilteredRecipes(
            recipes?.filter((recipe) => {
                const recipeTagStrings = recipe.tags.map((t) => t.content);
                return (
                    recipe.name
                        .toLowerCase()
                        .includes(filterText.toLowerCase()) &&
                    (tagFilters.length === 0 ||
                        tagFilters.every((t) =>
                            recipeTagStrings.includes(t.content)
                        )) &&
                    !recipesToExclude?.includes(recipe)
                );
            })
        );
    }, [recipes, filterText, tagFilters, recipesToExclude]);

    const filterRecipes = (filterString: string) => {
        setFilterText(filterString);
    };

    return (
        <section className="flex flex-col gap-[12px]">
            <h2>{titleOverride ?? "All Recipes"}</h2>

            {isLoading && <div>Loading...</div>}
            {!isLoading && (
                <>
                    <TextInput
                        name="Filter"
                        placeholder="Filter..."
                        value={filterText}
                        onChange={filterRecipes}
                    />

                    <TagFilter
                        tags={tags}
                        handleTagFilter={setTagFilters}
                        className="flex mb-3"
                    />

                    {filteredRecipes?.length > 0 && (
                        <RecipeRows
                            recipes={filteredRecipes}
                            onClick={onRecipeClick}
                            rowIcon={rowIcon}
                        />
                    )}
                    {filteredRecipes?.length === 0 && (
                        <div>No recipes match the filter.</div>
                    )}
                </>
            )}
        </section>
    );
};

export default SearchableRecipes;
