import React from "react";
import { useNavigate } from "react-router-dom";
import { useGetApi } from "common/hooks/useGetApi";
import PlusButton from "foundation/components/PlusButton";
import { Cookbook } from "cooking/models/types";

type MyCookbooksProps = {};

const MyCookbooks: React.FC<MyCookbooksProps> = () => {
    const navigate = useNavigate();
    const { data: cookbooks, isLoading } = useGetApi<Cookbook[]>(
        "cookbooks/my_cookbooks"
    );

    return (
        <section className="space-y-4">
            <div className="flex gap-[15px] items-center">
                <h2>My Cookbooks</h2>
                <PlusButton
                    size="16"
                    onClick={() => navigate("cookbook/create")}
                />
            </div>

            {isLoading && <div>Loading...</div>}
            {!isLoading && (
                <ul className="pl-0 space-y-2">
                    {cookbooks?.map((cookbook) => (
                        <li
                            className="border-b-[1px] border-solid border-b-theme-border last:border-b-0"
                            key={cookbook.id}
                        >
                            <button
                                className="w-full py-[5px] text-left"
                                onClick={() =>
                                    navigate(`cookbook/view/${cookbook.id}`)
                                }
                            >
                                {cookbook.name} ({cookbook.recipes.length}{" "}
                                Recipes)
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
};

export default MyCookbooks;
