import React from "react";
import { useCurrentUser } from "foundation/hooks/useCurrentUser";
import { getThemeTextColor } from "helpers/settings.helpers";

type TrashProps = {
    className?: string;
    size?: string;
    color?: string;
};

const Trash: React.FC<TrashProps> = ({
    className = "",
    size = "25px",
    color: _color,
}) => {
    const { currentUser } = useCurrentUser();
    const color = _color || getThemeTextColor(currentUser);

    return (
        <svg
            version="1.1"
            viewBox="50 0 600 550"
            xmlns="http://www.w3.org/2000/svg"
            fill={color}
            className={className}
            style={{ height: size, width: size, marginBottom: "-4px" }}
        >
            <g>
                <path d="m577.5 70h-105v-17.5c0-28.949-23.551-52.5-52.5-52.5h-140c-28.949 0-52.5 23.551-52.5 52.5v17.5h-105c-9.6641 0-17.5 7.8281-17.5 17.5s7.8359 17.5 17.5 17.5h455c9.6719 0 17.5-7.8281 17.5-17.5s-7.8281-17.5-17.5-17.5z" />
                <path d="m560 140h-420c-9.4961 0-17.934 8.2227-17.484 18.285l15.844 351.83c1.3945 27.977 24.43 49.887 52.43 49.887h318.42c28.012 0 51.047-21.91 52.43-49.973 0 0 15.801-350.81 15.844-351.74 0.44922-9.8828-7.6055-18.285-17.48-18.285zm-301.4 314.98c-0.25391 0.015625-0.51172 0.015625-0.76562 0.015625-9.3125 0-17.062-7.3477-17.465-16.746l-8.2539-192.5c-0.41797-9.6562 7.0742-17.824 16.73-18.234 9.6484-0.42578 17.824 7.0742 18.234 16.73l8.2539 192.5c0.41797 9.6523-7.0781 17.824-16.734 18.234zm108.9-17.484c0 9.6719-7.8359 17.5-17.5 17.5s-17.5-7.8281-17.5-17.5v-192.5c0-9.6719 7.8359-17.5 17.5-17.5s17.5 7.8281 17.5 17.5zm92.113 0.75391c-0.41016 9.3984-8.1523 16.746-17.465 16.746-0.25781 0-0.51172 0-0.76953-0.015625-9.6562-0.41016-17.141-8.5781-16.73-18.234l8.2734-192.5c0.42578-9.6562 8.7148-17.125 18.234-16.73 9.6562 0.41016 17.141 8.5781 16.73 18.234z" />
            </g>
        </svg>
    );
};

export default Trash;
