import React from "react";
import { Routes as DomRoutes, Route } from "react-router-dom";

import Home from "foundation/components/Home";

import LoginForm from "auth/components/LoginForm";
import SignupForm from "auth/components/SignupForm";
import SettingsHome from "settings/components/SettingsHome";
import CookingHome from "cooking/components/CookingHome";
import CreateRecipeNew from "cooking/components/CreateRecipeNew";
import ViewRecipe from "cooking/components/ViewRecipe";
import MakeRecipe from "cooking/components/MakeRecipe";
import CreateMealPlan from "cooking/components/CreateMealPlan";
import ManageIngredients from "cooking/components/ManageIngredients";
// import Kitchen from "cooking/components/Kitchen";
import Kitchen from "cooking/components/KitchenNew";
import FantasyFootballHome from "fantasyFootball/components/FantasyFootballHome";
import DraftPrep from "fantasyFootball/components/DraftPrep";
import ManageNflPlayers from "fantasyFootball/components/ManageNflPlayers";
import ManageStats from "fantasyFootball/components/ManageStats";
import ManageNflTeams from "fantasyFootball/components/ManageNflTeams";
import PointsPerGame from "fantasyFootball/components/PointsPerGame";
import ManageFantasyTeams from "fantasyFootball/components/ManageFantasyTeams";
import ManageFantasyLeagues from "fantasyFootball/components/ManageFantasyLeagues";
import CreateFantasyLeague from "fantasyFootball/components/CreateFantasyLeague";
import WimHome from "wim/components/WimHome";
import QuotesHome from "quotes/components/QuotesHome";
import RememberallHome from "rememberall/components/RememberallHome";
import MoneyHome from "money/components/MoneyHome";
import SkillsHome from "skills/components/SkillsHome";
import TrackSkillsPage from "skills/components/TrackSkillsPage";
import CreateCookbook from "cooking/components/CreateCookbook";
import ViewCookbook from "cooking/components/ViewCookbook";

const Routes: React.FC = () => {
    return (
        <DomRoutes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />

            <Route path="/settings" element={<SettingsHome />} />

            {/* ========= Cooking 🍳 ========= */}
            <Route path="/cooking" element={<CookingHome />} />
            <Route
                path="/cooking/recipe/create"
                element={<CreateRecipeNew />}
            />
            <Route
                path="/cooking/recipe/edit/:recipeId"
                element={<CreateRecipeNew />}
            />
            <Route
                path="/cooking/recipe/view/:recipeId"
                element={<ViewRecipe />}
            />
            <Route
                path="/cooking/recipe/make/:recipeId"
                element={<MakeRecipe />}
            />
            <Route
                path="cooking/meal_plan/create"
                element={<CreateMealPlan />}
            />
            <Route path="cooking/ingredients" element={<ManageIngredients />} />
            <Route path="cooking/kitchen" element={<Kitchen />} />
            <Route
                path="cooking/cookbook/create"
                element={<CreateCookbook />}
            />
            <Route
                path="/cooking/cookbook/view/:cookbookId"
                element={<ViewCookbook />}
            />

            {/* ========= Rememberall 🔮 ========= */}
            <Route path="/rememberall" element={<RememberallHome />} />

            {/* ========= FantasyFootball 🏈 ========= */}
            <Route path="/fantasyFootball" element={<FantasyFootballHome />} />
            <Route path="/fantasyFootball/draftPrep" element={<DraftPrep />} />
            <Route
                path="/fantasyFootball/manage/nflPlayers"
                element={<ManageNflPlayers />}
            />
            <Route
                path="/fantasyFootball/manage/stats"
                element={<ManageStats />}
            />
            <Route
                path="/fantasyFootball/manage/nflTeams"
                element={<ManageNflTeams />}
            />
            <Route
                path="/fantasyFootball/manage/fantasyLeagues"
                element={<ManageFantasyLeagues />}
            />
            <Route
                path="/fantasyFootball/manage/fantasyLeagues/create"
                element={<CreateFantasyLeague />}
            />
            <Route
                path="/fantasyFootball/manage/fantasyTeams"
                element={<ManageFantasyTeams />}
            />
            <Route path="/fantasyFootball/ppg" element={<PointsPerGame />} />

            {/* ========= Wim 🎹 ========= */}
            <Route path="/wim" element={<WimHome />} />

            {/* ========= Quotes 🤔 ========= */}
            <Route path="/quotes" element={<QuotesHome />} />

            {/* ========= Money 💲 ========= */}
            <Route path="/money" element={<MoneyHome />} />

            {/* ========= Skills 📊 ========= */}
            <Route path="/skills" element={<SkillsHome />} />
            <Route path="/skills/track" element={<TrackSkillsPage />} />
        </DomRoutes>
    );
};

export default Routes;
